/* Main Container Grid  */
.main-container-grid {
  margin-top: 14px;
  max-width: 1080px;
}

/* Top Section  */
.section-grid {
  padding: 28px;
}

.bc-paper {
  padding: 28px 28px 16px;
  margin: 18px 10px;
}

.paper-title-grid {
  margin-bottom: 16px;
  max-width: 400px;
}

.paper-title {
  font-weight: 600;
  font-size: 1.25em;
  color: #0a2740;
  margin-left: 4px;
}

.privacy-paper-subtitle {
  font-size: 13px;
  margin-left: 4px;
}

.settings-paper {
  padding: 28px 28px 16px;
  margin: 10px;
}

.components-subheading {
  font-size: 13px;
  font-family: Open Sans, sans-serif;
  font-weight: bold;
  color: #0a2740;
  padding: 11px 16px;
  border-top: 1px solid #caccce;
}
