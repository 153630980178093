/* Main Container Grid  */
.main-container-grid {
  margin-top: 14px;
  max-width: 1080px;
}

/* Top Section  */
.section-grid {
  padding: 28px;
}

.bc-paper {
  padding: 28px 28px 16px;
  margin: 18px 10px;
}

.bot-profile-title-grid {
  display: flex;
  align-items: center;
  color: #0a2740;
}

.paper-title {
  font-weight: 600;
  font-size: 1.25em;
  color: #0a2740;
  margin-left: 4px;
}

.bot-profile-paper-subhead {
  font-weight: 600;
  font-size: 1.1em;
  color: #0a2740;
  margin: 12px 4px 16px;
}

.bot-profile-subtitle {
  font-size: 13px;
  margin: 6px 0 0 4px;
}

.bot-profile-paper {
  padding: 28px 28px 16px;
  margin: 10px;
}

.custom-field-grid {
  margin: 10px 0 4px;
}

.bot-profile-paper-divider {
  height: 1px;
  background-color: #cbcbcb;
  margin: 20px 6px 18px;
}
