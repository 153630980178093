/* Main Container Grid  */
.main-container-grid {
  margin-top: 14px;
  max-width: 1080px;
}

/* Top Section  */
.section-grid {
  padding: 28px;
}

.bc-paper {
  padding: 28px 28px 16px;
  margin: 18px 10px;
}

.paper-title-grid {
  margin-bottom: 16px;
  max-width: 400px;
}

.paper-title {
  font-weight: 600;
  color: #0a2740;
  font-size: 1.25em;
}

.feedback-title-grid {
  display: flex;
  align-items: center;
  color: #0a2740;
}

.feedback-paper-subtitle {
  font-size: 13px;
  margin-left: 4px;
}

.settings-paper {
  padding: 28px 28px 16px;
  margin: 10px;
}

.count-paper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.count {
  margin-right: 6px;
  color: #f0f3f6;
  font-size: 16px;
}

.positive-tile,
.positive-tile-active,
.negative-tile,
.negative-tile-active {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 5px;
  cursor: pointer;
}

.positive-tile {
  background-color: green;
  color: white;
  border: 2px solid green;
  border-radius: 10px 0 0 10px;
}

.positive-tile-active {
  background-color: green;
  color: white;
  border: 2px solid #0a2740;
  border-radius: 10px 0 0 10px;
}

.negative-tile {
  background-color: #a01b1b;
  color: white;
  border: 2px solid#A01B1B;
  border-radius: 0 10px 10px 0;
}

.negative-tile-active {
  background-color: #a01b1b;
  color: white;
  border: 2px solid #0a2740;
  border-radius: 0 10px 10px 0;
}

.positive-tile-icon .negative-tile-icon {
  width: 20px;
  height: 20px;
}

/* Feedback received  */
.feedback-main-container {
  padding: 28px 28px 14px;
  border-top: 1px solid #e5e5e5;
}

.feedback-toggle-switch {
  margin-right: -14px;
}

.feedback-section {
  max-width: 950px;
  padding: 5px;
}

.feedback-title {
  color: #0a2740;
  font-weight: 600;
  font-size: 1.25em;
  padding: 12px 0;
  margin: 0 20px 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.feedback-title-tooltip-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.feedback-grid {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.feedback-record-container-positive {
  height: 100px;
  max-width: 290px;
  display: flex;
  flex-direction: row;
  border: 2px solid #0a2740;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  border: 2px solid #0a2740;
  margin: 10px;
  background-color: #4a9766;
}

.feedback-record-container-negative {
  height: 100px;
  max-width: 290px;
  display: flex;
  flex-direction: row;
  border: 2px solid #0a2740;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  border: 2px solid #0a2740;
  margin: 10px;
  background-color: #a01b1b;
}

.feedback-record-detail-container {
  display: flex;
  flex-direction: column;
  height: inherit;
  border-radius: 0 10px 10px 0;
  padding: 8px 8px 2px;
  background-color: #0a2740;
}

.thumb-container {
  min-height: 40px;
  min-width: 40px;
  border-radius: 50%;
  background-color: #f0f3f6;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 2px 8px 2px 8px;
}

.feedback-record-session-time-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 2px;
  min-width: 0;
}

.feedback-record-session-id {
  font-family: monospace;
  font-size: 0.77em;
  color: #709cbd;
}

.feedback-record-intent-trigger {
  font-family: monospace;
  font-size: 0.77em;
  color: #709cbd;
}

.feedback-record-comment {
  font-family: sans-serif;
  font-size: 13px;
  /* padding: 4px 0; */
  margin: 2px 0;
  color: #f0f3f6;
  max-height: 40px;
  overflow-y: auto;
}

.feedback-record-timestamp {
  font-family: monospace;
  color: #e5e5e5;
  font-size: 0.77em;
}
